<template>
  <el-dialog
    :visible.sync="dialogVisible"
    custom-class="globalSuccessTipsStatus"
    top="0"
    :append-to-body="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
  >
    <div class="iconBox">
      <span class="iconfont_Me icon-check iconStyle"></span>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "globalSuccessTips",
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    dialogVisible() {
      return this.$store.state.globalShare.globalSuccessTipsStatus;
    },
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.$store.commit("setGlobalSuccessTipsStatus", false);
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
</script>
<style lang="stylus">
.globalSuccessTipsStatus
  width: 50px;
  background: transparent;
  box-shadow: 0 0 0;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
    background: transparent;
    .iconBox
      width 50px;
      height 50px;
      border-radius 50%;
      background #33CC66;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 26px;
        color #FFFFFF;
</style>
