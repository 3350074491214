var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "custom-class": "globalSuccessTipsStatus",
        top: "0",
        "append-to-body": true,
        "close-on-click-modal": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "iconBox" }, [
        _c("span", { staticClass: "iconfont_Me icon-check iconStyle" })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }